import {CurrencyDollarIcon, OfficeBuildingIcon, UserGroupIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components/macro';
import {Sheet, StandardButton} from '../../Components';
import {OrganizationSummary} from '../OrgAdmin/OrganizationSummary';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {useRemoveExtraSubscriptions} from '../../API/Mutations/useRemoveExtraSubscripions';
import {useUpdateExistingOrganizations} from '../../API/Calls/useIpdateExistingOrganizations';

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 120px;
  left: 20px;
  width: auto;
  gap: 20px;
`;

export const AdminMenu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectAction = (id: string) => {
    navigate(`/admin/${id}`);
  };

  // TODO: If we had more time I would have made a navigation-specific variant of Sheet, but we're out of budget
  let activeItem = 'organizations';
  if (location.pathname.includes('settings') || location.pathname.includes('settings')) {
    activeItem = 'settings';
  } else if (location.pathname.includes('users')) {
    activeItem = 'users';
  }

  return (
    <Wrapper>
      <Sheet
        items={[
          {id: 'organizations', label: 'Organizations', icon: <OfficeBuildingIcon />, active: activeItem === 'organizations'},
          {id: 'settings', label: 'Subscription Pricing', icon: <CurrencyDollarIcon />, active: activeItem === 'settings'},
          {id: 'users', label: 'Users', icon: <UserGroupIcon />, active: activeItem === 'users'},
        ]}
        onClick={(item) => handleSelectAction(item.id)}
        itemStyle={{marginBottom: 32}}
      />
      <OrganizationSummary />
      <RemoveExtraSubscriptionsButton />
      <UpdateOrganizationFromCSVButton />
    </Wrapper>
  );
};

const RemoveExtraSubscriptionsButton = () => {
  const {data: profile} = useMyProfile();
  const removeExtraSubscripions = useRemoveExtraSubscriptions();
  const handleClick = async () => {
    const res = await removeExtraSubscripions.mutateAsync();

    console.log({res});
  };

  if (!profile?.user?.email.includes('tyrannosaurus')) {
    return null;
  }

  return (
    <div style={{width: 200}}>
      <StandardButton disabled onClick={handleClick}>
        Remove Extra Subscriptions
      </StandardButton>
    </div>
  );
};

const UpdateOrganizationFromCSVButton = () => {
  const {data: profile} = useMyProfile();

  const updateExistingOrganizations = useUpdateExistingOrganizations();
  const handleClick = async () => {
    const res = await updateExistingOrganizations.mutateAsync();
    console.log({res});
  };

  if (!profile?.user?.email.includes('tyrannosaurus')) {
    return null;
  }

  return (
    <div style={{width: 200}}>
      <p>This will update primary_email and create trial subscriptions based on the organizations from a CSV file.</p>
      <StandardButton disabled onClick={handleClick}>
        Update Organizations From CSV
      </StandardButton>
    </div>
  );
};
