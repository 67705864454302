import {TrashIcon, UserAddIcon} from '@heroicons/react/outline';
import {Field, FieldArray, Form, FormikProps, useFormikContext} from 'formik';
import {FC, useCallback, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {IOrganization, IUser, useUpdateUser} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {
  BoldFieldLabel,
  Dropdown,
  ErrorBody,
  FieldWrapper,
  FlexRow,
  FormField,
  H4,
  H6,
  InputWrapper,
  RequiredSpan,
  StandardButton,
  Subhead1,
  Theme,
} from '../../Components';
import {useRoles} from '../../Hooks/useRoles';
import {EmailField} from './EmailField';

export const Container = styled.div`
  gap: 1rem;
`;

export const FieldRow = styled(FlexRow)`
  margin-bottom: 1rem;
  gap: 1rem;
  width: 100%;
`;

export const Button = styled(StandardButton)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
`;

export interface OrganizationMembersFormProps {
  formikProps: FormikProps<IOrganization>;
  you?: IUser | undefined;
  onChange: (values: any) => void;
  hideTitle?: boolean;
  readOnly?: boolean;
}

const MAX_ADMIN_COUNT = 2;

export const OrganizationMembersForm: FC<OrganizationMembersFormProps> = ({
  formikProps: {handleSubmit, values, errors},
  // you,
  onChange,
  hideTitle,
  readOnly,
}) => {
  const {values: updatedValues}: {values: IOrganization} = useFormikContext();
  const {data: myProfile} = useMyProfile();
  const {isAdmin: iAmStaff, isOrgAdmin: iAmOrgAdmin} = useRoles();

  const defaultRoleOptions = [
    {label: 'Default', value: 'user'},
    {label: 'Admin', value: 'org-admin'},
  ];

  const staffRoleOptions = [{label: 'Staff', value: 'admin'}];
  const defaultUser = {id: '', email: '', user_type: 'user'};

  useEffect(() => onChange(updatedValues), [updatedValues]);

  const updateUser = useUpdateUser();

  const handleChangeUserType = useCallback(
    async (user_type: string, user: IUser) => {
      try {
        await updateUser.mutateAsync({userId: user.id, user_type});
        console.log('updated', user);
      } catch (error) {
        console.log('update error', error);
      }
    },
    [updateUser],
  );

  // find index of user with myProfile email
  const myProfileIndex = values?.users?.findIndex((user: IUser) => user.email === myProfile?.user?.email) || -1;

  // if myProfile email is found, and I am Staff, make sure I am Staff in the form
  if (values?.users && myProfileIndex > -1 && iAmStaff) {
    values.users[myProfileIndex].user_type = 'admin';
  }

  const adminUsersIndexes =
    values?.users?.reduce((acc: number[], user: IUser, index: number) => {
      if (user.user_type === 'org-admin') {
        acc.push(index);
      }
      return acc;
    }, []) || [];

  const isMaxOrgAdmins = adminUsersIndexes.length > MAX_ADMIN_COUNT - 1;

  const exceedsMaxAdmins = adminUsersIndexes.length > MAX_ADMIN_COUNT;

  let meCount = 0;

  const newUserRef = useRef<HTMLDivElement | null>(null);

  return (
    <Form id="onboarding-members-form" style={{height: '100%'}} onSubmit={handleSubmit}>
      <FieldArray
        name="users"
        render={(arrayHelpers) => (
          <>
            <FlexRow style={{marginBottom: '18px', gap: 16}}>
              {!hideTitle ? (
                <H4 style={{flex: 2, margin: 0}}>Add Members</H4>
              ) : (
                <H6 style={{flex: 2, margin: 0}}>Current Members on Subscription</H6>
              )}
              {!readOnly && (
                <StandardButton
                  disabled={!!errors?.users}
                  outline={true}
                  style={{
                    display: 'flex',
                    flexShrink: 0,
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    whiteSpace: 'nowrap',
                  }}
                  type="button"
                  onClick={() => {
                    arrayHelpers.unshift(defaultUser);
                    setTimeout(() => {
                      if (newUserRef.current) {
                        newUserRef.current.scrollIntoView({behavior: 'smooth'});
                      }
                    }, 0);
                  }}>
                  <UserAddIcon style={{width: 18, marginRight: '8px'}} />
                  Add New Member
                </StandardButton>
              )}
            </FlexRow>

            {exceedsMaxAdmins && <Subhead1 style={{color: Theme.Colors.Reds._000, marginBottom: 16}}>Max Admins Reached: 2/2</Subhead1>}

            {errors?.users && typeof errors?.users === 'string' && (
              <Subhead1 style={{color: Theme.Colors.Reds._000, marginBottom: 16}}>{errors?.users}</Subhead1>
            )}

            <Container>
              {values?.users?.map((user, index) => {
                // If a user is Staff, that role will show here, but it can't be changed
                const isStaff = user?.user_type === 'admin';

                const skipOrgAdminOption = isMaxOrgAdmins && !adminUsersIndexes.includes(index);

                const adjustedOptions = skipOrgAdminOption ? defaultRoleOptions.slice(0, 1) : defaultRoleOptions;

                const options = isStaff ? staffRoleOptions : adjustedOptions;

                const isMe = user?.email === myProfile?.user?.email;

                if (isMe) {
                  meCount++;
                }

                const defaultValue =
                  isStaff || (isMe && iAmStaff) ? staffRoleOptions[0] : adjustedOptions.find((option) => option.value === user?.user_type);

                // // debounce check for duplicate
                // const handleEmailChange = useAsyncDebounce((value) => {
                //   // setName(value || undefined);
                // }, 200);

                if (isMe && !isStaff && !iAmStaff && meCount === 1)
                  return (
                    <FieldRow style={{maxWidth: 'calc(100% - 65px)'}} key={`users[${index}].email`}>
                      <FormField style={{flex: 1}}>
                        <FieldWrapper>
                          <BoldFieldLabel>Member Email (You)</BoldFieldLabel>
                        </FieldWrapper>

                        <p style={{margin: 0, color: '#6A6F7B', fontSize: '14px', wordBreak: 'break-word'}}>{myProfile?.user?.email}</p>
                      </FormField>

                      <FormField style={{flex: 1, marginBottom: 0}}>
                        <FieldWrapper>
                          <BoldFieldLabel>Member Role (You)</BoldFieldLabel>
                        </FieldWrapper>

                        <p style={{margin: 0, color: '#6A6F7B', fontSize: '14px'}}>Admin</p>
                      </FormField>
                    </FieldRow>
                  );

                return (
                  <FieldRow
                    key={`sortedUsers[${index}].email`}
                    style={{flexWrap: 'nowrap', order: index + 1}}
                    ref={index === 0 ? newUserRef : null}>
                    <FormField style={{flex: 2, margin: 0, minWidth: 200}}>
                      <EmailField
                        disabled={(isStaff && index === 0) || !!readOnly}
                        label={isMe ? 'Member Email (You)' : 'Member Email'}
                        name={`users[${index}].email`}
                        organizationId={values?.id}
                      />
                    </FormField>

                    <FormField style={{flex: 1, margin: 0, minWidth: 120}}>
                      <Field name={`users[${index}].user_type`}>
                        {({
                          field,
                          form,
                          meta,
                        }: {
                          field: {name: string; value: string};
                          form: {setFieldValue: (field: string, value: any) => void};
                          meta: {touched: boolean; error: string};
                        }) => (
                          <FieldWrapper>
                            <BoldFieldLabel>
                              Member Role
                              {!readOnly && !isStaff && <RequiredSpan> *</RequiredSpan>}
                            </BoldFieldLabel>

                            <InputWrapper>
                              <Dropdown
                                defaultValue={defaultValue}
                                isSearchable={false}
                                name={field.name}
                                options={options}
                                disabled={isStaff || (isMe && iAmStaff) || readOnly}
                                onChange={(option: {value: string}) => {
                                  form.setFieldValue(field.name, option.value);
                                  if ((iAmStaff || iAmOrgAdmin) && !!updatedValues?.id) {
                                    handleChangeUserType(option.value, user);
                                  }
                                }}
                              />
                            </InputWrapper>

                            {meta?.error && <ErrorBody>{meta?.error}</ErrorBody>}
                          </FieldWrapper>
                        )}
                      </Field>
                    </FormField>

                    {!readOnly && (
                      <Button style={{marginTop: '28px'}} type="button" onClick={() => arrayHelpers.remove(index)} disabled={false}>
                        <TrashIcon style={{width: 24}} />
                      </Button>
                    )}
                  </FieldRow>
                );
              })}
            </Container>
          </>
        )}
      />
    </Form>
  );
};
