import React, {FC} from 'react';
import {SimpleSelect} from './TableLayout';

interface UserTypeCellProps {
  value: any;
  row: any;
  onChange: (value: string, row: any) => void;
  disabled?: boolean;
}

export const UserTypeCell: FC<UserTypeCellProps> = ({value: initialValue, row, onChange, disabled = false}) => {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (e: any) => {
    onChange(e.target.value, row);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (row.original.stripe_customer_id) return <>Stripe Customer</>;

  if (row.original.organizationId || !['user', 'admin'].includes(value)) return <>{value}</>;

  return (
    <SimpleSelect value={value || ''} onChange={handleChange} disabled={disabled}>
      <option value="admin">Staff</option>
      <option value="user">User</option>
    </SimpleSelect>
  );
};
