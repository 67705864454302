import {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {routePaths} from '../constants/routePaths';
import {useGetMyOrganization, useSubscriptionPricing} from '../API/Queries';
import {useCartOrganization} from './useCartOrganization';
import {useRoles} from './useRoles';
import {useMyProfile} from '../API/Queries/useMyProfile';

export const onboardingRoutes = [
  routePaths.onboardingOrganization,
  routePaths.onboardingMembers,
  routePaths.onboardingLocations,
  routePaths.onboardingPayment,
  routePaths.onboardingDefaultLocation,
];

export const cboOnboardingRoutes = [routePaths.onboardingOrganization, routePaths.onboardingLocal];

export const useOnboardingSteps = () => {
  const {data: myProfile} = useMyProfile();
  const location = useLocation();
  const {data: organization} = useGetMyOrganization(!!myProfile?.user?.organizationId);
  const {organization: cartOrganization} = useCartOrganization();
  const {data: subscriptionPricing} = useSubscriptionPricing();

  const {isOrgAdmin} = useRoles();

  const orgHasAuthorizations = !!cartOrganization?.authorizations?.length;
  const orgHasSubscriptions = !!organization?.subscription;
  const orgHasUsers = !!cartOrganization?.users?.length;
  const userHasOrganization = !!organization?.id;
  const isNotForProfit = cartOrganization && cartOrganization?.subscription_type === subscriptionPricing?.subscriptionTypes?.local?.value;

  const getFurthestCompleted = useCallback(() => {
    if (orgHasSubscriptions) {
      return routePaths.onboardingPaymentConfirmed;
    }

    if (orgHasAuthorizations) {
      return routePaths.onboardingPayment;
    }

    if (orgHasUsers) {
      return routePaths.onboardingLocations;
    }

    if (isNotForProfit) {
      return routePaths.onboardingLocal;
    }

    return routePaths.onboardingOrganization;
  }, [userHasOrganization, isOrgAdmin, orgHasSubscriptions, orgHasAuthorizations, orgHasUsers]);

  const stepMap = isNotForProfit ? cboOnboardingRoutes : onboardingRoutes;

  const currentStep = stepMap.indexOf(location.pathname);
  const completedStep = stepMap.indexOf(getFurthestCompleted());

  return {completedStep, currentStep: currentStep < 0 ? 1 : currentStep, stepMap};
};
